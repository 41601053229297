@font-face {
  font-family: 'Big Shoulders Stencil Text';
  src: url("BigShouldersStencilText-Regular.ttf");
  font-weight: normal;
}

@font-face {
  font-family: 'Big Shoulders Stencil Text';
  src: url("BigShouldersStencilText-Bold.ttf");
  font-weight: bold;
}


* {
  box-sizing: border-box;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
}

html {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100%;
  height: 100%;
}


body {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100%;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  display: block;
  width: 100%;
  min-height: 100%;
  height: 100%;
}

h1 {
  margin: 0;
  padding: 0;
}